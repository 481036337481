<template>
  <div>
    <nav-bar class="title_top" :icon="'img/backb.png'" :textColor="'#000000'" :backColor="'none'" />
    <div style="padding:0 20px;">
        <p>我们深知个人信息安全与隐私保护非常重要，本指引将帮助您直观、简明地了解我们是如何收集、处理使用个人信息以及如何保护您的隐私。</p>
        <p>我们会在向你提供服务过程中使用你主动提供的，以及经过你的授权通过自动化手段收集的下列信息：</p>
        <p>（1）个人身份信息</p>
        <p>（2）联系方式</p>
        <p>（3）地理位置</p>
        <p>（4）摄像头</p>
        <p>（5）麦克风</p>
        <p>（6）剪切板</p>
        <p>（7）设备信息（包括但不限于OAID&imei等）</p>
        <p>（8）存储空间</p>
        <div class="title">一．个人身份与联络方式</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">注册登录：</span>我们需要通过手机号码进行帐号注册、登录。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">实名认证：</span>我们需要通过身份证号码、姓名等信息完成特定场景下的实名制认证。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">安全保障：</span>在为保障安全等特定必要场景下，我们需要通过身份证信息相结合的方式来识别或认证您的身份。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">与您联系：</span>我们可能通过手机号码向您发送运营、推广、活动等信息，您可以拒绝或退订我们或运营方发送的上述信息。</p>
        <div class="title">二．地理位置</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>GPS等精确位置信息需要您额外单独授权，同时WLAN接入点、蓝牙、基站以及IP地址等信息也可以描述设备所在的大致位置。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>通常我们会使用位置信息推送相关信息或广告，帮助您在发布作品时展示定位位置。例如：在「动态圈」列表中，我们会使用IP位置确定您当前所在的城市，以为您搜索并展示同城相关的内容。</p>
        <div class="title">三．摄像头与麦克风</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>麦克风与摄像头均为敏感权限，我们只有在您需要发布作品、开直播等功能所需时开启。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>我们绝不会私自开启麦克风、摄像头窃取您的隐私或收集您日常聊天时的对话，更不会采用此种非法手段进行信息、广告推送。</p>
        <div class="title">四.剪切板</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>通过分享回流方式参与活动时，我们会对剪切板中是否有符合活动规则的信息在手机本地进行判断，不会将您写入剪切板的个人隐私信息回传。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">统计分析：</span>我们可能需要将应用来源渠道信息通过剪切板进行统计，在统计过程中我们不会将您写入剪切板的个人隐私信息回传。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>有些手机等终端设备的系统会提示应用（App）正在读取剪切板，但该系统提示无法区分是否收集剪切板信息，且不排除误报。因此，如果您发现类似提示，请与我们联系，以便我们定位问题。</p>
        <div class="title">五．设备信息</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>设备信息是指设备标识符（保护但不限于OAID、imei、IDFA），网络接入信息（包含但不限于IP、MAC地址等），手机型号，手机厂商，系统版本等等，设备信息在手机终端中可能被表述为“设备信息”，这不代表App会收集通讯内容或您主动输入的隐私信息。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>设备标识信息对于向未登录的用户推送其可能感兴趣的内容或广告具有不可替代的作用。设备信息也通常用在反作弊和判断帐号是否处于安全的环境中。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">特别说明：</span>我们不会主动获取您的imei信息，由于您的设备可能存在不支持或获取不到OAID的情况，我们将在询问您再获取您的设备imei信息。目前多数手机终端厂商已经支持对设备标识符的更改，以保障您对个人信息的控制权，具体撤回的途径和方式可以参见您所使用的终端设备说明。</p>
        <div class="title">六．存储空间</div>
        <p style="text-indent: unset;"><span style="font-weight:bold;">概要：</span>存储空间权限是指提供写入外部储存功能，允许写入、删除存储空间及照片的权限。</p>
        <p style="text-indent: unset;"><span style="font-weight:bold;">使用目的：</span>我们会申请存储权限，用于App或小程序写入、下载、保存、缓存、修改、删除图片、文件、崩溃日志等信息。</p>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue';
export default {
  name: 'userinfoList',
  components: {
    NavBar
  },
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
.hint{
  margin: 30px 0 50px;
}
.hint p{
  font-size: 32px;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
}
.title_top{
    position: relative;
    padding-top: 65px;
}
</style>
